import React from "react"

import { Grid } from "@mui/material"
import { useTheme } from "@mui/material/styles"

export const AppliedFiltersContainer = ({ children }) => {
  const theme = useTheme()

  return (
    <Grid
      container
      spacing={2}
      data-testid="applied-filters"
      alignItems="center"
      sx={{
        [theme.breakpoints.down("md")]: {
          flexWrap: "nowrap",
          overflowX: "scroll"
        },
        pb: 0,
        "& > .MuiGrid-item": { pb: "4px", pt: 0 }
      }}
    >
      {children}
    </Grid>
  )
}
